import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_1/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { language: { eq: "FR" }, title: { eq: "slide-1-1-2" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              author
              authorInfo
              title
            }
            body
          }
        }
      }
      file(
        relativePath: { eq: "chapter_1/sub_1/parallax/cytat_1.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
